import { Navbar, Stack } from 'react-bootstrap';
import s from './HeadBar.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { DownloadAppBtn } from '/entities/DownloadAppBtn';
import { HeaderLogo } from '/entities/HeaderLogo';

interface HeadBarProps {
  className?: string;
}

export const HeadBar = ({ className }: HeadBarProps) => {
  //TODO скачать - переход на страницу
  return (
    <Navbar expand='lg' className={classNames(s.HeadBar, {}, [className])}>
      <Stack direction='horizontal' className={s.wrapper}>
        <HeaderLogo />
        {/* <DownloadAppBtn /> */}
      </Stack>
    </Navbar>
  );
};
