import { Image } from 'react-bootstrap';
import s from './CarouselItem.module.scss';
import { SyntheticEvent, useCallback } from 'react';

interface CarouselItemProps {
  src: string;
}

export const CarouselImage = ({ src }: CarouselItemProps) => {
  return <Image src={src} draggable='false' className={s.img} height='200px' />;
};

export const CarouselVideo = ({ src }: CarouselItemProps) => {
  const onloadstart = useCallback((e: SyntheticEvent<HTMLVideoElement>) => {
    if (e.nativeEvent?.target) {
      (e.nativeEvent.target as HTMLVideoElement).volume = 0.1;
    }
  }, []);

  return (
    <video
      // muted
      controls
      onLoadStart={onloadstart}
      draggable='false'
      className={s.img}
      height='200px'
    >
      <source src={src} type='video/mp4'></source>
    </video>
  );
};

export const CarouselItem = ({ src }: CarouselItemProps) => {
  return src.endsWith('.mp4') ? <CarouselVideo src={src} /> : <CarouselImage src={src} />;
};
