import { memo, useMemo } from 'react';
import { Carousel as BCarousel } from 'react-bootstrap';
import arrowLeft from '/shared/assets/icons/arrow left.svg?react';
import arrowRight from '/shared/assets/icons/arrow right.svg?react';
import { Icon } from '/shared/ui/Icon';
import { ImgSrcItem } from '../model/type/ImgSrcItem';
import { API_URL } from '/shared/api/rtkApi';
import { CarouselItem } from './CarouselItem/CarouselItem';
import './CardCarousel.scss';

interface CardCarouselProps {
  imgSrcs?: ImgSrcItem[];
  className?: string;
}

export const CardCarousel = memo((props: CardCarouselProps) => {
  const { className, imgSrcs } = props;

  const defaultCar = useMemo(
    () => (
      <BCarousel
        className={className}
        controls
        touch={false}
        indicators
        prevIcon={<Icon Svg={arrowLeft} />}
        nextIcon={<Icon Svg={arrowRight} />}
      >
        <BCarousel.Item key='default'>
          <CarouselItem src='/img/customImg.jpg' />
        </BCarousel.Item>
      </BCarousel>
    ),
    [],
  );

  if (!imgSrcs?.length) {
    return defaultCar;
  }

  return (
    <BCarousel
      className={className}
      controls
      touch
      indicators={false}
      prevIcon={<Icon Svg={arrowLeft} />}
      nextIcon={<Icon Svg={arrowRight} />}
      interval={null}
    >
      {imgSrcs?.map((src, i) => (
        <BCarousel.Item key={`${src.name}_${i}`}>
          <CarouselItem src={`${API_URL}/file/load/${src.name}`} />
        </BCarousel.Item>
      ))}
    </BCarousel>
  );
});
